


















































































import Vue from 'vue'
import Component from 'vue-class-component';
import Breadcrumb from '@/components/common/Breadcrumb.vue';
import Widget from '@/components/common/Widget.vue';
import DataTable from '@/components/table/DataTable.vue';

declare const $:any;

@Component({components: {Breadcrumb, Widget, DataTable}})
export default class AgencyManagement extends Vue
{
  tableColums = [
    {
      label: "ID",
      prop: "id",
      sortable: true
    },
    {
      label: "Agency Name",
      prop: "name",
      sortable: true
    },
    {
      label: "Kanzleiadmin",
      prop: "manager",
      sortable: true
    },
    {
      label: "E-Mail Adresse",
      prop: "email",
      sortable: true
    },
  ]
  perPage = 25;
  orderby = "id";
  orderDirection = "asc";
  totalItems = 0;
  offsetPage = 0;
  tableRows = [
    {
      id: 'A239636',
      name: 'Sony',
      manager: 'Mrs. Floyd Miles',
      email: 'nevaeh.simmons@example.com'
    },
    {
      id: 'A239636',
      name: 'The Walt Disney Company',
      manager: 'Mrs. Arthur Flores',
      email: 'scott.gilbert@example.com'
    },
    {
      id: 'A239636',
      name: 'Gillette',
      manager: 'Mr. Jane Mckinney',
      email: 'genesis.nichols@example.com'
    },
    {
      id: 'A239636',
      name: 'IBM',
      manager: 'Mr. Soham Fisher',
      email: 'darrell.duncan@example.com'
    },
  ]
  name = ''
  manager = ''
  email = ''
  userId = ''

  add() {
    $('#add_modal').modal()
  }

  edit(event: any) {
    this.name = event.name
    this.manager = event.manager
    this.email = event.email
    this.userId = event.id
    this.add()
  }

  deleteItem(event: any) {
    this.tableRows.splice(this.tableRows.findIndex(row => row.id === event.id), 1)
  }

  addUser() {
    if (this.userId) {
      this.tableRows.forEach(row => {
        if (row.id === this.userId) {
          row.name = this.name
          row.manager = this.manager
          row.email = this.email
        }
      })
    } else {
      this.tableRows.push({
        id: '22222',
        name: this.name,
        manager: this.manager,
        email: this.email
      })
    }
  }

  changeSortDirection(val: any) {
    this.orderDirection = val;
  }

  changeSortProperty(val: any) {
    this.orderby = val;
    this.orderDirection = 'asc';
  }

  changePerPage(val: any): void {
    if (val) {
      let offsetPage = val - 1;
      if (offsetPage !== this.offsetPage) {
        this.offsetPage = offsetPage;
      }
    }
  }
}
